<template>
  <v-container id="user-table" fluid tag="section">
    <participants-form v-if="meta.participant" :data="meta.participant" ref="editForm"
      @itemSaved="onParticipantSaved"></participants-form>
    <base-panel icon="mdi-account-check" :title="title">
      <v-row>
        <v-col sm="12" lg="6">
          <v-card v-if="meta.quiz" elevation="3">
            <v-card-title class="primary darken-1 white--text">
              <v-icon color="white" class="mr-2">
                mdi-file-question-outline
              </v-icon>
              {{ meta.quiz.name }}
              <v-spacer> </v-spacer>
              <a @click="onEditQuiz">
                <v-icon small color="white hover">mdi-pencil</v-icon>
              </a>
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  {{ meta.quiz.instructions }}
                </v-list-item>
                <v-list-item>
                  {{ meta.quiz.remarks }}
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" md="6" lg="3">
          <v-card v-if="meta.quiz" elevation="3">
            <v-card-title class="primary darken-1 white--text">
              <v-icon color="white" class="mr-2"> mdi-account </v-icon>
              {{ meta.participant.name }}
              <v-spacer> </v-spacer>
              <a @click="onEditParticipant" v-if="meta">
                <v-icon small color="white hover">mdi-pencil</v-icon>
              </a>
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  {{ meta.participant.email }}
                </v-list-item>
                <v-list-item>
                  {{ $t("gender_" + meta.participant.gender) }} - {{ age }}
                </v-list-item>
                <v-list-item>
                  {{ meta.participant.notes }}
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" md="6" lg="3">
          <v-card v-if="meta.quiz" elevation="3">
            <v-card-title class="primary darken-1 white--text">
              <v-icon color="white" class="mr-2"> mdi-directions </v-icon>
              {{ $t("norm.category") }}
            </v-card-title>
            <v-card-text>
              <v-select :items="meta.categories" item-text="category_name" item-value="id" v-model="norm"
                @change="updateNorm">
              </v-select>
              <v-btn :disabled="!norm" color="blue-grey" class="white--text" @click="onChart">
                <v-icon left> mdi-chart-box-outline </v-icon>
                {{ $t("result.report pdf") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" md="6" lg="6">
          <v-card v-if="meta.history && meta.history.length > 1" elevation="3">
            <v-card-title class="primary darken-1 white--text">
              <v-icon color="white" class="mr-2"> mdi-calendar-range </v-icon>
              {{ $t("result.history") }}
            </v-card-title>
            <v-card-text>
              <v-list>
                <div v-for="h in meta.history" :key="'hist_' + h.id">
                  <span v-if="h.id == rid">{{ moment(h.end_time).format("LLL") }} -
                    {{ h.quiz_participant_id }}</span>
                  <a v-else @click="viewHistory(h)">{{ moment(h.end_time).format("LLL") }} -
                    {{ h.quiz_participant_id }}</a>
                </div>
              </v-list>
              <v-btn :disabled="!norm" color="blue-grey" class="white--text" @click="onHistoryChart">
                <v-icon left> mdi-chart-box-plus-outline </v-icon>
                {{ $t("result.history pdf") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card elevation="3">
        <v-card-title class="primary darken-1 white--text">
          <v-icon color="white" class="mr-2">mdi-checkbox-marked-circle
          </v-icon>
          {{ $t("result.answers") }}
          <div class="spacer"></div>
          <div>
            <v-icon v-if="meta.quiz && meta.quiz.formula" small color="white hover"
              :title="meta.quiz.formula">mdi-crosshairs-question</v-icon>
            {{ $t("result.score") }}: {{ total_score }}
          </div>
        </v-card-title>
        <v-data-table v-if="norm" :headers="headers" :items="items" :options.sync="options" :loading="loading"
          hide-default-footer group-by="subscale_id" class="elevation-1" show-group-by>
          <template v-slot:[`item.answers`]="{ item }">
            <v-chip v-if="item.type == 'text'">{{ item.response_text }}</v-chip>
            <v-chip v-for="answer in item.answers" :key="'ans' + answer.id" :small="answer.id != item.response"
              :class="answer.id == item.response ? 'yellow pr-1' : 'pr-0'" class="mr-2 mb-1">
              {{ answer.answer_text }}
              <v-chip pill small class="ml-1 mr-0 px-2" :color="answer.id == item.response ? 'orange' : 'white'">
                {{ answer.answer_score }}
              </v-chip>
            </v-chip>
          </template>
          <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
            <th colspan="2" width="30%">
              {{ $t("quiz.subscale") + ": " + items[0].scale_name }}
              <v-chip v-if="items[0].norm" small class="cyan px-3 ml-2">
                {{ $t("norm." + items[0].norm.formula) }}:
                {{ items[0].norm.min_val }} - {{ items[0].norm.max_val }}
              </v-chip>
            </th>
            <th>
              <v-chip v-if="items[0].norm" class="px-3" color="orange">
                {{ getScore(items) }}
              </v-chip>
              <v-icon @click="toggle" class="float-right">
                {{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
            </th>
          </template>
        </v-data-table>
      </v-card>
    </base-panel>
  </v-container>
</template>

<script>
import ParticipantsForm from "@/pages/clients/ParticipantsForm";
import crudMixin from "mixins/crudTable";
import moment from "moment";

export default {
  mixins: [crudMixin],
  components: {
    ParticipantsForm,
  },
  data() {
    return {
      apiQuery: "/quiz-results/",
      headers: [
        {
          text: this.$t("quiz.number"),
          value: "number",
          groupable: false,
          sortable: false,
        },
        {
          text: this.$t("quiz.question"),
          value: "question_text",
          groupable: false,
          sortable: false,
        },
        {
          text: this.$t("quiz.answer"),
          value: "answers",
          groupable: false,
          sortable: false,
        },
        { text: this.$t("quiz.subscale"), value: "subscale_id" },
      ],
      items: [],
      norm: null,
      rid: 0,
      options: {
        itemsPerPage: 10000,
      },
      total_score: "-",
    };
  },
  created() {
    this.rid = this.$route.params.id;
    this.get("/quiz-results/" + this.rid).then((data) => {
      this.meta = data;
      if (this.$route.params.norm) {
        for (var i in data.categories) {
          if (data.categories[i].id == this.$route.params.norm) {
            this.norm = data.categories[i].id;
            break;
          }
        }
      } else {
        this.norm = data.categories[0].id;
      }
      this.total_score = data.score;
      this.uuid = data.quiz_participant_id;
      this.$set(this.options, "category", this.norm);
    });
    this.apiQuery = "/quiz-results/" + this.rid + "/answers";
    moment.locale(this.$i18n.locale);
  },
  computed: {
    title() {
      if (this.meta.quiz) {
        return (
          this.meta.quiz.name +
          " | " +
          this.meta.participant.name +
          " | " +
          moment(this.meta.end_time).format("LLL")
        );
      }
      return this.$t("loading");
    },
    age() {
      if (this.meta.participant.birth_year) {
        return new Date().getFullYear() - this.meta.participant.birth_year;
      }
      return "";
    },
  },
  methods: {
    moment,
    onChart() {
      this.download(
        "/quiz-results/" + this.rid + "/pdf/" + this.norm,
        this.meta.participant.name +
        "-" +
        this.meta.quiz.name +
        "-" +
        this.uuid +
        "-" +
        this.getNormName() +
        ".pdf"
      ).catch((error) => {
        this.alert({
          text: error.message,
          type: "error",
          title: "Oops",
        });
      });
    },
    getNormName() {
      for (var i in this.meta.categories) {
        if (this.meta.categories[i].id == this.norm) {
          return this.meta.categories[i].category_name;
        }
      }
      return "";
    },
    getScore(items) {
      if (!items[0].norm) {
        return "";
      }
      let acc = 0;
      for (var i in items) {
        acc += items[i].score;
      }
      if (items[0].norm.formula == "avg") {
        acc = acc / items.length;
      }
      return acc;
    },
    updateNorm() {
      if (this.options.category != this.norm) {
        this.$set(this.options, "category", this.norm);
      }
    },
    viewHistory(h) {
      this.rid = h.id;
      this.apiQuery = "/quiz-results/" + this.rid + "/answers";
      this.meta.end_time = h.end_time;
      this.$set(this.options, "rid", this.rid);
      this.uuid = h.quiz_participant_id;
      return false;
    },
    onHistoryChart() {
      this.download(
        "/quiz-results/" + this.rid + "/historypdf/" + this.norm,
        this.meta.participant.name +
        "-" +
        this.meta.quiz.name +
        "-" +
        this.$t("result.history") +
        "-" +
        this.getNormName() +
        "-" +
        this.moment().format("D-M-Y_H-m") +
        ".pdf"
      ).catch((error) => {
        this.alert({
          text: error.message,
          type: "error",
          title: "Oops",
        });
      });
    },
    onEditQuiz() {
      this.$router.push("/quizzes/" + this.meta.quiz.id);
    },
    onEditParticipant() {
      this.$refs.editForm.editItem(this.meta.participant, 0);
    },
    onParticipantSaved(item) {
      this.meta.participant = item;
    },
  },
};
</script>

<style scoped>
.v-chip--pill.white {
  border: 1px solid #999 !important;
  box-shadow: 0 0 20px #999;
}

.v-chip--pill.orange,
.v-chip.orange {
  border: none !important;
  box-shadow: 0 0 12px #f69300;
}
</style>
